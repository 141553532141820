import { useState } from "react";
import { useSelector } from "react-redux";
import infoMyApps from "../components/_infoMyApps";

const MyApp = (props) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const imgClassname = isImgLoaded ? "block" : "hidden";
  const skeletonClassname = isImgLoaded ? "hidden" : "block";
  const {
    name,
    nameDetail,
    tools,
    description,
    linkToApp,
    linkToRepo,
    bgColor,
    imgUrl,
  } = props.app;

  const videoSkeleton = {
    "--bgColor": bgColor,
    height: "100%",
    borderRadius: "2rem",
  };

  return (
    <section className="myapp-info">
      <section>
        <section className="myapp-title">
          <h2 className="myapp-name">
            {name}:<span> {nameDetail}</span>
          </h2>
        </section>
        <h4 className="myapp-tools">
          {tools.map((tool) => (
            <span key={tool}>{tool}</span>
          ))}
        </h4>

        <p className="myapp-description">{description}</p>
      </section>
      <section>
        <a className="myapp-image" target="_blank" href={linkToApp}>
          <div className={`${skeletonClassname} img-skeleton`}>
            <div style={videoSkeleton} className="video-skeleton" />
          </div>
          <img
            target="_blank"
            className={imgClassname}
            href={linkToApp}
            src={imgUrl}
            alt={description}
            onLoad={() => setIsImgLoaded(true)}
          />
        </a>
      </section>
      <section>
        <a
          className="btn btn-main myapp-linkToApp"
          target="_blank"
          href={linkToApp}
        >
          View App
        </a>
        {linkToRepo && (
          <a
            className="btn btn-main myapp-linkToApp"
            target="_blank"
            href={linkToRepo}
            style={{ marginLeft: "1rem" }}
          >
            View Repo
          </a>
        )}
      </section>
    </section>
  );
};

const MyApps = () => {
  const isMobileMenuActive = useSelector(
    (state) => state.ultilities.isMobileMenuActive
  );
  const appList = () => {
    return infoMyApps.map((app) => {
      return <MyApp app={app} key={app.name} />;
    });
  };
  return (
    <section
      className={`page-others page-myapps fade ${
        isMobileMenuActive ? "page-inactive" : ""
      }`}
    >
      {/* <h1>My Apps</h1> */}
      {appList()}
    </section>
  );
};

export default MyApps;
