import { IoLogoLinkedin, IoLogoGithub, IoLogoTwitter } from "react-icons/io";

const SocialLinks = () => {
  return (
    <section id="social-links">
      <a href="https://www.linkedin.com/in/lukeist/">
        <IoLogoLinkedin className="social-icon" />
      </a>
      <a href="https://github.com/lukeist">
        <IoLogoGithub className="social-icon" />
      </a>
      <a href="https://x.com/lukeist_">
        <IoLogoTwitter className="social-icon" />
      </a>
    </section>
  );
};

export default SocialLinks;
